// Form Component
// --------------
.form {
  margin-top: 2 * $base-font-size;

  &.first-child {
    margin-top: $base-font-size;
  }

  p {
    font-size: $base-font-size;
  }

  ul {
    padding-top: $base-font-size;
    padding-left: 1.5 * $base-font-size;
  }

  &__error-list {
    padding-bottom: $base-font-size;

    p {
      padding-top: 0;
    }

    ul {
      padding-left: 1.5 * $base-font-size;

      li {
        font-size: 1.1 * $base-font-size;

        span {
          font-weight: 400;
          font-size: inherit;
        }
      }
    }
  }

  &__message {
    padding: $base-font-size;
    margin: $base-font-size 0;
    border-radius: $default-border-radius;

    span {
      font-weight: 400;
      font-size: $base-font-size;
    }

    &--success {
      color: $black-colour;
      background-color: $accent-colour;
    }

    &--error {
      color: $white-colour;
      background-color: $alert-colour;
    }
  }

  &__field {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__spacer {
      height: $base-font-size;
    }

    &__label {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 400;
        font-size: $base-font-size;
      }

      height: 2.5 * $base-font-size;
      line-height: 2.5 * $base-font-size;

      &__desc {
        font-size: 0.75 * $base-font-size !important;
        padding-left: 0.5 * $base-font-size;
        color: $secondary-colour;
        line-height: 2.5 * $base-font-size;
        flex: auto;
      }
    }

    &__input {
      width: 100%;
      display: flex;
      // gap: $base-font-size;
      flex-direction: row;

      &--with-error {
        flex-direction: column;
        gap: 0.5 * $base-font-size;

        input {
          border: 1px solid $alert-colour !important;
        }
      }

      &__error {
        font-size: $base-font-size;
        color: $alert-colour;
      }

      button,
      .button {
        height: 2.5 * $base-font-size;
        line-height: 2.5 * $base-font-size;
        margin-right: 0;
        margin-left: $base-font-size;
        padding: 0 0.75 * $base-font-size;
      }

      input {
        width: 100%;
      }

      .icon-remove {
        font-size: $base-font-size;
        font-weight: 400;
        line-height: 2.5 * $base-font-size;

        &:before {
          content: "\e5cd";
        }
      }
    }
  }

  &__buttons {
    margin: $base-font-size 0;
    display: flex;
    gap: 1rem;

    .button {
      margin: 0;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

// Form Container
.form-container {
  padding: 1.5 * $base-font-size;
  border-radius: 1 * $base-font-size;
  margin-bottom: 2 * $base-font-size;

  &--neutral-grey {
    background-color: $neutral-grey-colour;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"] {
    background-color: $white-colour;
  }
}
