.did-you-know {
  img {
    margin-bottom: $base-font-size;
  }

  blockquote {
    width: 100%;
    margin-top: 0;
  }

  &__source {
    font-size: 1.2 * $base-font-size;
  }

  @media all and (min-width: 1020px) {
    img {
      width: 45%;
      float: left;
      margin: 0.5 * $base-font-size 1.5 * $base-font-size $base-font-size 0;
    }
  }
}
