.roles-card {
  margin-top: $base-font-size;
  padding-bottom: 2 * $base-font-size;
  border-bottom: 2px dotted $black-colour;

  &.last-child {
    border: 0;
    padding-bottom: 0;
  }

  &__specialities {
    padding: $base-font-size;
    border-radius: 0.5 * $base-font-size;
    background-color: $neutral-grey-colour;
    margin-top: $base-font-size;
    padding-bottom: 1.5 * $base-font-size;

    &__title {
      // display: inline-block;
      font-size: $base-font-size;
      margin-bottom: 0.5 * $base-font-size;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__item {
      padding: 0.3 * $base-font-size 0;
      border-bottom: 1px solid $black-colour;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      label {
        padding-left: 0.3 * $base-font-size;
      }

      select {
        background-color: $neutral-grey-colour;
        width: auto;
        height: 1.7 * $base-font-size;
        padding: 0 0.5 * $base-font-size;
        text-align: end;
      }
    }
  }
}
