// All Elements
// ----------
* {
  font-family: $primary-font-family;
  font-weight: $base-font-weight;
  font-size: $base-font-size;
}

// Bold
// ----
b {
  font-size: inherit;
  font-weight: 400;
}

// Italic
i {
  font-size: 1.2 * $base-font-size;
}

// Headings
// --------
h1,
h2,
h3,
h4 {
  font-family: $secondary-font-family;
  font-weight: $heavy-font-weight;
  font-optical-sizing: auto;
}

h1 {
  font-size: 1.8 * $base-font-size;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

h2 {
  font-size: 1.7 * $base-font-size;
}

h3 {
  font-size: 1.5 * $base-font-size;
  font-style: italic;
  padding-bottom: 0.5 * $base-font-size;
  // background-color: $yellow-colour;
  // max-width: fit-content;
  // padding: 0 $base-font-size;
  // min-height: 2.5 * $base-font-size;
  // line-height: 2.5 * $base-font-size;
  // border-left: 3px solid $black-colour;
}

h4 {
  font-size: 1.3 * $base-font-size;
}

// Text
// ----
span {
  font-size: 1.2 * $base-font-size;
}

// Paragraphs
// ----------
p {
  font-size: 1.4 * $base-font-size;
  padding: 0;
  padding-bottom: 1.5 * $base-font-size;

  a {
    font-weight: 400;
  }
}

// Unordered List
// --------------
ul {
  padding-left: 1.5 * $base-font-size;

  li {
    font-size: 1.2 * $base-font-size;
    line-height: 1.6 * $base-font-size;
    margin-bottom: $base-font-size;
  }
}

@media all and (min-width: 760px) {
  ul {
    li {
      max-width: max-content;
      font-size: 1.4 * $base-font-size;
      line-height: 2 * $base-font-size;
    }
  }
}
