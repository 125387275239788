.dashboard-page {
  display: grid;
  grid-template-areas:
    "contact"
    "role"
    "location"
    "buttons";
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;

  @media all and (min-width: 760px) {
    grid-template-areas:
      "contact role"
      "location role"
      "buttons buttons";
    grid-template-columns: calc(50% - $base-font-size);
    grid-column-gap: 2 * $base-font-size;

    .card {
      margin-top: 0;

      &.contact-details-card {
        margin-bottom: 2 * $base-font-size;
      }

      &.location-details-card {
        border-bottom: 0;
      }
    }

    .form {
      &__buttons {
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }

  @media all and (min-width: 850px) {
    max-width: 850px;
    margin: 0 auto;
  }
}

.contact-details-card {
  grid-area: contact;
}

.location-details-card {
  grid-area: location;
}

.role-details-form {
  grid-area: role;
}

.dashboard-main-buttons {
  grid-area: buttons;
}
