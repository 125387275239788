// Button
// ------
.button {
  display: inline-block;
  text-align: center;
  // text-transform: uppercase;
  cursor: pointer;

  font-family: $primary-font-family;
  font-size: $base-font-size;
  font-weight: $heavy-font-weight;

  // height: $default-button-height;
  line-height: $default-button-height;

  margin-right: $base-font-size;
  padding: $default-button-padding;
  border-radius: $default-button-radius;
  border-width: 0;

  &--primary {
    color: $neutral-colour;
    background-color: $primary-colour;
  }

  &--secondary {
    color: $white-colour;
    background-color: $secondary-colour;
  }

  &--neutral {
    color: $black-colour;
    background-color: $mid-grey-colour;
    // border: 1px solid $black-colour;
  }

  &--accent {
    color: $black-colour;
    background-color: $accent-colour;
  }

  &--link {
    color: $black-colour;
    background-color: $white-colour;
  }

  &--subtle {
    color: $white-colour;
    background-color: $dark-grey-colour;
  }

  &--small {
    font-size: 0.8 * $base-font-size;
    margin: 0;
    padding: 0.25 * $base-font-size 0.5 * $base-font-size;
    line-height: $base-font-size;
  }

  &--primary-darker {
    color: $white-colour;
    background-color: $primary-darker-colour;
  }

  &--light-blue {
    color: $black-colour;
    background-color: $light-blue-colour;
  }
}

button:disabled,
button[disabled] {
  color: $dark-grey-colour;
  background-color: $neutral-grey-colour;
  // text-decoration: line-through;
  border: 1px solid $neutral-grey-colour;
}
