// App Layout
// ----------
.app-layout {
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-rows: auto auto auto;
  grid-template-columns: 100%;

  background-color: $white-colour;
}

// App Page Content
// ----------------
.app-page-content {
  grid-area: content;
  box-sizing: border-box;
  padding: 1.5 * $base-font-size;

  div:first-child {
    margin-top: 0;
  }

  p {
    font-size: 1.1 * $base-font-size;
    margin: $base-font-size 0;
    padding: 0;

    a {
      font-size: $base-font-size;
    }
  }

  .form {
    margin-top: $base-font-size;
  }
}

// App Header
//-----------
.app-header {
  grid-area: header;
  padding: 1.5 * $base-font-size 2 * $base-font-size;
  padding-bottom: 0.5 * $base-font-size;
  color: $white-colour;
  background-color: $primary-darker-colour;

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: $base-font-size;
  }

  a,
  a:active,
  a:visited,
  a:hover {
    color: $white-colour;
    font-size: 1.2 * $base-font-size;
    font-weight: 400;
  }

  ul.app-menu {
    direction: rtl;
    margin-left: auto;
    margin-right: 0;
    list-style: none;

    li {
      // line-height: 1.5 * $base-font-size;
      margin: 0.75 * $base-font-size 0;
    }

    &--large-screen {
      display: none;
    }
  }

  .text-logo {
    display: inline-block;

    &__d {
      font-weight: 400;
      margin-left: 0.5 * $base-font-size;
    }

    &__beta {
      margin-left: 0.5 * $base-font-size;
      color: $yellow-colour;
      font-size: 1.1 * $base-font-size;
    }
  }

  .icon-app-menu {
    font-size: 1.5 * $base-font-size;

    &:before {
      content: "\e5d2";
    }

    &--close {
      &:before {
        content: "\e5cd";
      }
    }

    @media all and (min-width: 760px) {
      display: none;
    }
  }

  @media all and (min-width: 760px) {
    // padding: $base-font-size 1.5 * $base-font-size;
    padding: 0 $base-font-size;

    nav {
      // padding-bottom: 0.5 * $base-font-size;
      padding-bottom: 0;
    }

    a {
      margin: auto 0;
      line-height: 1.5 * $base-font-size;
    }

    ul.app-menu {
      display: flex;
      margin-right: $base-font-size;

      li {
        margin-left: $base-font-size;

        a {
          line-height: 1rem;
          font-size: 1rem !important;
          font-weight: 100 !important;
        }
      }

      &--large-screen {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

// App Footer
// ----------
.app-footer {
  grid-area: footer;
}

// Error Container
.error-container {
  width: 100%;
  max-width: 850px;
  padding: $base-font-size;
  margin: 0 auto;
  background: $yellow-colour;
  border-radius: 0.5 * $base-font-size;
  margin-bottom: 2 * $base-font-size;
}

// Save Bar
.app-save-bar {
  position: fixed;
  width: 100%;
  height: 5rem;
  background-color: yellow;
  bottom: 0;
  left: 0;
}

.auto-save {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgb(245 104 104 / 80%);
  color: $white-colour;
  padding: 0.5 * $base-font-size $base-font-size;
  border-radius: 0 0.25 * $base-font-size 0 0;
  font-size: $base-font-size;
  font-weight: 400;
}
