// Icon List
.icon-list {
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: $base-font-size;
  margin: $base-font-size 0;

  &__item {
    display: flex;
    flex-direction: row;

    &__icon {
      background: $yellow-colour;
      color: $black-colour;
      border-radius: 50%;
      text-align: center;

      .material-symbols-outlined {
        font-size: 1.8 * $base-font-size;
        padding: 0;
        margin: 0;
      }
    }

    &__text {
      font-size: 1.5 * $base-font-size;
      width: calc(100% - 4 * $base-font-size - $base-font-size);
      padding-left: $base-font-size;
      display: flex;
      justify-content: center;
      flex-direction: column;

      p {
        margin: 0;
        margin-top: 1rem;
      }
    }
  }

  &--large {
    .icon-list__item {
      &__icon {
        width: 4 * $base-font-size;
        height: 4 * $base-font-size;
        font-size: 2 * $base-font-size;
        line-height: 4 * $base-font-size;

        .material-symbols-outlined {
          font-size: 1.8 * $base-font-size;
        }
      }

      &__text {
        font-size: 1.5 * $base-font-size;
        width: calc(100% - 4 * $base-font-size - $base-font-size);
      }
    }
  }

  @media all and (min-width: 1020px) {
    &.lg-cols-2 {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2 * $base-font-size;
      grid-auto-flow: column;
    }

    &.lg-cols-3 {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2 * $base-font-size;
      grid-auto-flow: column;
    }

    &.lg-rows-3 {
      grid-template-rows: repeat(3, 1fr);
    }
  }
}

@media all and (min-width: 760px) {
  .icon-list {
    margin-bottom: 0;

    &__item {
      &__text {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .icon-list {
    // display: flex;
    // flex-direction: row;

    &__item {
      // flex-grow: 1;
      // flex-basis: 0;

      &__icon {
        width: 6 * $base-font-size;
        height: 6 * $base-font-size;
        font-size: 2.4 * $base-font-size;
        line-height: 6 * $base-font-size;

        .material-symbols-outlined {
          font-size: 2 * $base-font-size;
        }
      }
    }
  }
}
