// Text & Password Inputs
// ----------------------
input[type=text],
input[type=password],
input[type=number] {
  border: 0;
  height: 2.5 * $base-font-size;
  line-height: 2.5 * $base-font-size;
  border-radius: .25 * $base-font-size;
  padding: 0 .75 * $base-font-size;
  font-size: $base-font-size;
  background-color: #eee;
}