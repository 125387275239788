// Website Layout
// --------------
.website-layout {
  height: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-rows: minmax(max-content, 10 * $base-font-size) auto minmax(
      max-content,
      10 * $base-font-size
    );
  grid-template-columns: 100%;
}

// Website Header
//---------------
.website-header {
  grid-area: header;
  background-color: $white-colour;

  .website-section-wrapper {
    border-bottom: 1px solid $black-colour;

    @media all and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
    }
  }

  &__contents {
    display: flex;
    justify-content: space-between;
    // padding-top: 2 * $base-font-size;
    // padding-bottom: $base-font-size;

    @media all and (min-width: 760px) {
      grid-template-areas:
        "header-logo top-nav"
        "header-title header-title"
        "header-subtitle header-subtitle";

      grid-template-rows: 10 * $base-font-size auto auto;

      .website-section-wrapper {
        padding-bottom: 2 * $base-font-size;
      }
    }

    @media all and (min-width: 1200px) {
      grid-template-rows: 6 * $base-font-size auto auto;
    }
  }

  &__logo {
    margin: $base-font-size 0;
    grid-area: header-logo;
    display: inline-block;

    a {
      color: $black-colour;
      font-size: 2 * $base-font-size;
      font-weight: 700;
      font-family: $secondary-font-family;
      display: block;
    }

    .svg {
      height: inherit;
    }
  }

  &__slogan {
    display: none;
    color: $black-colour;
    font-size: 1.5 * $base-font-size;
  }

  &__title {
    grid-area: header-title;
  }

  &__subtitle {
    grid-area: header-subtitle;
  }
}

// Website Page Content
// --------------------
.website-page-content {
  display: flex;
  grid-area: content;
  box-sizing: border-box;
  flex-direction: column;

  background-color: $white-colour;

  // @media all and (min-width: 850px) {
  //   max-width: 850px;
  //   margin: 0 auto;
  // }
}

// Website Footer
// --------------
.website-footer {
  display: flex;
  grid-area: footer;

  color: $dark-grey-colour;
  background-color: $black-colour;

  &__notes {
    display: flex;
    flex-direction: column;
    padding-top: 2 * $base-font-size;
    row-gap: 2 * $base-font-size;
  }

  &__logo {
    // padding-top: 2 * $base-font-size;
    color: $dark-grey-colour;
    font-size: 1.2 * $base-font-size;
    font-weight: 200;
    font-family: $secondary-font-family;

    .svg {
      height: inherit;
    }
  }

  &__copyright {
    display: block;
    font-size: $base-font-size;
    padding-top: 0.5 * $base-font-size;
  }

  &__cookies {
    h4 {
      font-size: 1.2 * $base-font-size;
      margin-bottom: 0.5 * $base-font-size;
    }

    a {
      color: $white-colour;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $dark-grey-colour;

    &__header {
      font-weight: 400;
      font-size: 1.4 * $base-font-size;
      color: $mid-grey-colour;
      margin-bottom: 0.5 * $base-font-size;
      display: block;
      font-family: $secondary-font-family;
    }

    &__item {
      height: 2 * $base-font-size;
      line-height: 2 * $base-font-size;
      font-size: 1.3 * $base-font-size;
      color: $dark-grey-colour;
    }

    div {
      width: 100%;
      padding-bottom: 2 * $base-font-size;
    }
  }

  .website-section-wrapper {
    padding-top: 3 * $base-font-size;
    padding-bottom: 1.5 * $base-font-size;
  }

  @media all and (min-width: 760px) {
    &__menu {
      flex-direction: row;
      padding-bottom: 0;

      div {
        width: fit-content;

        span {
          font-size: 1.2 * $base-font-size;
          margin: 0;
          margin-bottom: 0.5 * $base-font-size;
        }

        ul {
          li {
            height: 1.5 * $base-font-size;
            line-height: 1.5 * $base-font-size;
            font-size: 1.1 * $base-font-size;
          }
        }
      }
    }
  }

  @media all and (min-width: 1020px) {
    &__menu {
      div {
        min-width: 15%;
      }
    }

    &__notes {
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__cookies {
      width: 40%;
      text-align: justify;
      margin-bottom: 1rem;
    }
  }
}

// Home page launch banner
// -----------------------
.homepage-launch-banner {
  padding-top: 2 * $base-font-size;
  background-color: $yellow-colour;

  p {
    margin: $base-font-size 0;
  }

  .button {
    width: 100%;
    margin-top: 1.5 * $base-font-size;
  }

  @media all and (min-width: 760px) {
    padding: 0;
    padding-top: 2 * $base-font-size;

    .button {
      width: fit-content;
      margin: 0;
      margin-left: auto;
      margin-top: $base-font-size;
      display: block;
    }
  }
}

// Thought Bubble
.thought-bubble {
  display: flex;
  background-color: $yellow-colour;
  padding: 1.4 * $base-font-size;
  border-radius: 2 * $base-font-size;
  max-width: 100%;
  margin: 2 * $base-font-size 0;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;

  div {
    padding-top: $base-font-size;
    z-index: 1;
  }

  &:before,
  &:after {
    content: "";
    background-color: $yellow-colour;
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 0;
  }

  &:before {
    width: 44px;
    height: 44px;
    top: -12px;
    left: 28px;
    box-shadow: -50px 30px 0 -12px $yellow-colour;
  }

  &:after {
    bottom: -10px;
    right: 26px;
    width: 30px;
    height: 30px;
    box-shadow: 40px -34px 0 0 $yellow-colour, -28px -6px 0 -2px $yellow-colour,
      -24px 17px 0 -6px $yellow-colour, -5px 25px 0 -10px $yellow-colour;
  }

  @media all and (min-width: 1020px) {
    max-width: 80%;
    margin: 2 * $base-font-size auto;
    padding-bottom: 0.5 * $base-font-size;
  }
}

// Checklist call-to-action
.checklist-sign-up-button {
  width: 80%;
  max-width: 25 * $base-font-size;
  margin: 1.5 * $base-font-size auto;
  font-size: 1.3 * $base-font-size !important;
  padding: 0.7 * $base-font-size 1.5 * $base-font-size !important;
  line-height: 2 * $base-font-size !important;
  margin-bottom: $base-font-size;
  margin-right: 0;
}
