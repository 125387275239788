// Card
// ----
.card {
  height: fit-content;
  // margin-top: 2 * $base-font-size;
  margin-top: $base-font-size;
  // padding-bottom: 2rem;
  // border-bottom: 1px solid $black-colour;

  &.last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    a {
      font-size: 0.9 * $base-font-size;
      // margin-left: 0.5 * $base-font-size;
    }

    div {
      width: 100%;
    }
  }

  &__title {
    display: inline-block;
    // background: $neutral-grey-colour;
    background: $primary-darker-colour;
    color: $white-colour;
    padding: 0.5 * $base-font-size 1 * $base-font-size;
    border-radius: 0.25 * $base-font-size;
    width: 100%;
    margin-bottom: $base-font-size;
    // font-style: italic;
    // border-left: 0.2 * $base-font-size solid $black-colour;
    font-size: 1.1 * $base-font-size !important;
    font-family: $primary-font-family;
  }

  &__step-indicator {
    // display: inline-block;
    display: none;
    margin-left: 0.5 * $base-font-size;
  }

  &__breadcrumbs {
    width: 100%;

    span {
      font-size: inherit;

      &.active {
        font-weight: 400;
        color: $black-colour;
      }
    }
  }

  &__content {
    padding-bottom: $base-font-size;
  }

  &__buttons {
    // display: flex;
    display: none;
    flex-direction: row;
    justify-content: space-between;

    .button {
      margin: 0;
      background-color: $light-blue-colour;

      &:disabled {
        background-color: $neutral-grey-colour;
      }
    }

    &__next {
      padding: 0 0.75 * $base-font-size 0 1.5 * $base-font-size !important;

      .icon-next {
        vertical-align: middle;

        &:before {
          content: "\e5cc";
        }
      }
    }

    &__prev {
      padding: 0 1.5 * $base-font-size 0 0.75 * $base-font-size !important;

      .icon-prev {
        vertical-align: middle;

        &:before {
          content: "\e5cb";
        }
      }
    }
  }
}
