// Box-sizing
// ----------
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Width & Height
// --------------
html,
body,
#root {
  width: 100vw;
  height: 100vh;
  overflow-x: clip;
}

// Background Colour
// -----------------
body {
  background-color: $white-colour;
}

// Disable 'glow' effect on focus
// ------------------------------
textarea,
input,
button {
  outline: none;
}

*:focus {
  outline-color: transparent;
  outline-style: none;
}

// Anchor Tag
// ----------
a,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

p {
  a {
    font-size: 1.3 * $base-font-size;
  }
}

// Google Fonts
// ------------
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  font-size: 1.2 * $base-font-size;
}

// Images
// ------
img {
  width: 100%;
}
