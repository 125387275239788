.related-links {
  margin-bottom: 2 * $base-font-size;

  ul {
    padding: 0;
    margin-top: $base-font-size;
    list-style: none;

    li {
      border-left: $base-font-size solid $neutral-grey-colour;
      padding: 0.5 * $base-font-size 0;
    }
  }

  a {
    font-size: 1.3 * $base-font-size;
    padding-left: 1.5 * $base-font-size;
    font-weight: 400;
  }
}
