// Website Header Navigation
// -------------------------
.website-header-nav {
  display: none;
  grid-area: top-nav;

  a {
    font-size: inherit;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      a {
        font-size: 1.4 * $base-font-size;
        color: $black-colour;
        text-decoration: none;
        border: 0;
        margin-bottom: 0.5 * $base-font-size;
        display: block;
      }
    }
  }

  a.button.button--primary {
    width: 100%;
    color: $white-colour;
    margin-right: 0;
    margin-top: $base-font-size;

    @media all and (min-width: 431px) {
      min-width: 15 * $base-font-size;
      max-width: 15 * $base-font-size;
    }

    @media all and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  &__mobile {
    display: block;
    margin-bottom: 1.5 * $base-font-size;

    &__icon-chevron {
      vertical-align: top;
      font-size: 1.8 * $base-font-size;

      &:before {
        content: "\e5cc";
      }
    }

    @media all and (min-width: 1024px) {
      display: none !important;

      &__icon-chevron {
        display: none;
      }
    }
  }

  &__menu-icon {
    margin: $base-font-size 0;
    font-size: 2 * $base-font-size;
    line-height: 2 * $base-font-size;

    &:before {
      content: "\e5d2";
    }

    @media all and (min-width: 1024px) {
      display: none !important;
    }
  }

  @media all and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-grow: 1;
    min-height: 0;
    height: 100%;
    margin: auto 0;
    text-align: right;

    ul {
      text-align: left;
      min-height: fit-content;
      height: fit-content;

      li {
        display: inline-block;
        margin-bottom: 0;
        margin-right: $base-font-size;

        a {
          font-size: 1.2 * $base-font-size;
          margin-left: $base-font-size;
        }
      }
    }
  }
}
