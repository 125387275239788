// Auth Provider Buttons
// ---------------------
.auth-provider-buttons {
  margin-top: 2 * $base-font-size;
  // margin-bottom: 0;

  .button {
    width: 100%;
    margin: 0.5 * $base-font-size 0;
  }

  h4 {
    margin-bottom: $base-font-size;
  }
}
