
// TextEditor
// ----------
.text-editor {

	border: 0;
	resize: none;

	padding: 0.5 * $default-padding;

	font-size: 1.2 * $base-font-size;
	line-height: 1.2 * $base-font-size;
}