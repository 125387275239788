.settings-page {
  display: grid;
  grid-template-areas:
		"account"
    "membership";
	grid-template-rows: auto 1fr;
	grid-template-columns: 100%;

  @media all and (min-width: 760px) {
    grid-template-areas:
      "account membership";
    grid-template-columns: calc(50% - $base-font-size);
    grid-column-gap: 2 * $base-font-size;

    .settings-section {
      &.membership-settings-section {
        margin-top: 0;
      }

      .form {
        &__buttons {
          max-width: 400px;
          margin: 0 auto;

          .button {
            max-width: calc(200px - $base-font-size);
          }
        }
      }
    }
  }

  @media all and (min-width: 850px) {
    max-width: 850px;
    margin: 0 auto;
  }
}

// Sections/Blocks of the Settings page
// ------------------------------------
.settings-section {
  margin-top: 2 * $base-font-size;

  &__header {
    padding: 0.5 * $base-font-size 0;
    margin-bottom: 1.5 * $base-font-size;
    border-bottom: 1px solid $black-colour;
    font-size: 1.2 * $base-font-size;
  }

  &__content {

  }
}

.account-settings-section {
  grid-area: account;
}

.membership-settings-section {
  grid-area: membership;
}
