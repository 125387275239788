// Section Wrapper
// ---------------
.website-section-wrapper {
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 4 * $base-font-size);
  max-width: calc(100% - 4 * $base-font-size);
  height: inherit;
  margin: 0 auto;

  img {
    margin-bottom: 2 * $base-font-size;
    border-radius: $base-font-size;
  }

  // Info Cards
  &.info-card {
    background-color: $neutral-grey-colour;
    padding: 0;
    border-radius: $base-font-size;
    margin-bottom: $base-font-size;

    .text-section {
      padding: 0 1.5 * $base-font-size;
    }

    .image-section {
      margin: 0;
      padding: 0;

      img {
        margin-bottom: $base-font-size;
        border-radius: $base-font-size $base-font-size 0 0;
      }
    }

    &--primary-light {
      background-color: $light-blue-colour;
    }

    &--yellow {
      background-color: $yellow-colour;
    }

    @media all and (min-width: 760px) {
      margin-top: $base-font-size;
      margin-bottom: 2 * $base-font-size;
    }

    @media all and (min-width: 1024px) {
      .image-section {
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        border-radius: $base-font-size 0 0 $base-font-size;

        img {
          width: auto;
          height: 100%;
        }
      }

      .text-section {
        padding: 2 * $base-font-size;
        padding-left: $base-font-size;
      }

      &--reversed {
        .image-section {
          border-radius: 0 $base-font-size $base-font-size 0;
        }

        .text-section {
          padding: 2 * $base-font-size;
          padding-right: $base-font-size;
        }
      }
    }
  }

  // Nested wrappers
  .website-section-wrapper {
    min-width: 100%;
    max-width: 100%;
  }

  &.first-child {
    padding-top: 2 * $base-font-size;
  }

  &.cols-2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .col.col-sm-100 {
      width: 100%;
    }

    @media all and (min-width: 760px) {
      .col.col-md-70 {
        width: calc(70% - $base-font-size);

        &-f {
          width: calc(70% - $base-font-size) !important;
        }
      }
    }

    @media all and (min-width: 1024px) {
      flex-direction: row-reverse;
      gap: 2 * $base-font-size;

      .col.col-lg-60 {
        width: calc(60% - $base-font-size);
      }

      .col.col-lg-40 {
        width: calc(40% - $base-font-size);
      }
    }

    @media all and (min-width: 1200px) {
      .col.col-xl-50 {
        width: calc(50% - $base-font-size);
      }

      .col.col-xl-40 {
        width: calc(40% - $base-font-size);
      }

      .col.col-xl-60 {
        width: calc(60% - $base-font-size);
      }
    }
  }

  &.home-page {
    ul {
      margin-top: $base-font-size;

      li {
        h3 {
          display: inline-block;
          font-size: 1.1rem;
          /* background-color: white; */
          /* height: 2.5rem; */
          /* line-height: 2.5rem; */
          font-family: "Libre Baskerville";
          font-style: normal;
          margin-right: 0.5rem;
          font-weight: 200;
        }
      }
    }

    @media all and (min-width: 760px) {
      ul {
        li {
          p {
            padding-left: 1.5 * $base-font-size;
          }
        }
      }
    }
  }

  &--primary {
    background-color: $primary-colour;
    color: $white-colour;
  }

  &--dark {
    background-color: $black-colour;
    color: $white-colour;
  }

  @media all and (min-width: 768px) {
    min-width: calc(100% - 6 * $base-font-size);
    max-width: calc(100% - 6 * $base-font-size);
    margin: 0 auto;
  }

  @media all and (min-width: 1200px) {
    min-width: calc(100% - 10 * $base-font-size);
    max-width: calc(100% - 10 * $base-font-size);
    margin: 0 auto;
  }
}
