// Font Settings
// -------------
$base-font-size: 1rem;
$base-font-weight: 200;
$heavy-font-weight: 400;
$primary-font-family: "Barlow", sans-serif;
$secondary-font-family: "Libre Baskerville", serif;

// Colours
// ------
$primary-colour: #44469b;
// $primary-colour: #3a377e;
$secondary-colour: #e60d47;
// $neutral-colour: #F4FFF9;
$neutral-colour: #ffe;
$accent-colour: #a7f5b5;
$white-colour: #ffffff;
$black-colour: #212121;
$alert-colour: #f56868;
$neutral-grey-colour: #eeeeee;
$mid-grey-colour: #dfdfdf;
$dark-grey-colour: #a4a4a4;
$light-blue-colour: #e9e5f6;
$yellow-colour: #ffe01b;
// $yellow-colour: #f4f513;
$primary-darker-colour: #412f6a;
$primary-lighter-colour: #6e70af;

// Spacing: Margins, Paddings, Gutters, etc.
// -----------------------------------------
$default-margin: $base-font-size;
$default-padding: $base-font-size;
$default-radius: $base-font-size;

// Bottom Navigation
// -----------------
// $bottom-navigation-height: 		3 * $base-font-size;

// Buttons
// -------
$default-button-height: 2.5 * $base-font-size;
$default-button-padding: 0.25 * $default-padding 1.5 * $default-padding;
$default-button-radius: 0.25 * $base-font-size;

// Border
// ------
$default-border-radius: 0.25 * $base-font-size;
