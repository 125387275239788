// Float classes
.float-left {
  float: left;

  &-f {
    float: left !important;
  }
}

// Flex classes
.display-flex {
  display: flex !important;
}

.sm-flex-col-reverse {
  @media all and (max-width: 759px) {
    flex-direction: column-reverse !important;
  }
}

.md-flex-col-reverse {
  @media all and (max-width: 1023px) {
    flex-direction: column-reverse !important;
  }
}

.lg-flex-col-reverse {
  @media all and (min-width: 1024px) {
    flex-direction: column-reverse;

    &-f {
      flex-direction: column-reverse !important;
    }
  }
}

.flex-direction-row {
  flex-direction: row;

  &-f {
    flex-direction: row !important;
  }
}

.align-top {
  align-items: start !important;
}

.align-self-start {
  align-self: start;
}

.justify-content-start {
  justify-content: start !important;
}

.flex-align-start {
  align-items: flex-start;

  &-f {
    align-items: flex-start !important;
  }
}

// List classes
ul.no-style {
  list-style: none;
  padding-left: 0;

  li {
    display: block;
    // height: 1.5 * $base-font-size;
    // line-height: 1.5 * $base-font-size;
  }
}

// Notification Block
.note-block {
  border-radius: $base-font-size;
  padding: 2 * $base-font-size;
  padding-bottom: 0;
  margin-bottom: 2 * $base-font-size;

  a {
    font-size: 1.2 * $base-font-size;
  }

  &--grey {
    background-color: $neutral-grey-colour;
  }
}

// Paddings
.pt-1 {
  padding-top: $base-font-size;

  &-f {
    padding-top: $base-font-size !important;
  }
}

.pt-2 {
  padding-top: 2 * $base-font-size;

  &-f {
    padding-top: 2 * $base-font-size !important;
  }
}

.pb-2 {
  padding-bottom: 2 * $base-font-size;

  &-f {
    padding-bottom: 2 * $base-font-size !important;
  }
}

@media all and (max-width: 759px) {
  .sm-p-1 {
    padding: $base-font-size;
  }

  .sm-pt-0 {
    padding-top: 0;
  }

  .sm-pt-1 {
    padding-top: $base-font-size;
  }

  .sm-pt-2 {
    padding-top: 2 * $base-font-size;
  }

  .sm-pb-0 {
    padding-bottom: 0;

    &-f {
      padding-bottom: 0 !important;
    }
  }
}

// Medium and above
@media all and (min-width: 760px) {
  .pt-md-1 {
    padding-top: $base-font-size;

    &-f {
      padding-top: $base-font-size !important;
    }
  }

  .pt-md-2 {
    padding-top: 2 * $base-font-size;

    &-f {
      padding-top: 2 * $base-font-size !important;
    }
  }
}

// Medium until Large
@media all and (min-width: 760px) and (max-width: 1019px) {
  .md-p-1 {
    padding: $base-font-size;
  }

  .md-pr-0 {
    padding-right: 0 !important;
  }

  .md-pt-1 {
    padding-top: $base-font-size !important;
  }

  .md-pt-2 {
    padding-top: 2 * $base-font-size !important;
  }

  .md-pl-3 {
    padding-left: 3 * $base-font-size;
  }
}
@media all and (min-width: 1020px) and (max-width: 1199px) {
  .lg-pt-2 {
    padding-top: 2 * $base-font-size !important;
  }

  .lg-pb-1 {
    padding-bottom: $base-font-size;

    &-f {
      padding-bottom: $base-font-size !important;
    }
  }
}

@media all and (min-width: 1200px) {
  .xl-pt-1 {
    padding-top: $base-font-size;
  }

  .xl-pt-2 {
    padding-top: 2 * $base-font-size;
  }

  .xl-pl-5 {
    padding-left: 5 * $base-font-size;
  }

  .xl-pb-2 {
    padding-bottom: 2 * $base-font-size;

    &-f {
      padding-bottom: 2 * $base-font-size !important;
    }
  }
}

// Margins
.mt-0 {
  margin-top: 0;

  &-f {
    margin-top: 0 !important;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mt-1 {
  margin-top: $base-font-size;

  &-f {
    margin-top: $base-font-size !important;
  }
}

.mt-2 {
  margin-top: 2 * $base-font-size;

  &-f {
    margin-top: 2 * $base-font-size !important;
  }
}

@media all and (max-width: 759px) {
  .sm-mt-1 {
    margin-top: $base-font-size;
  }

  .sm-mt-2 {
    margin-top: 2 * $base-font-size;
  }
}

@media all and (min-width: 760px) and (max-width: 1019px) {
  .md-mr-0 {
    margin-right: 0 !important;
  }

  .md-mt-1 {
    margin-top: $base-font-size !important;
  }

  .md-mt-2 {
    margin-top: 2 * $base-font-size !important;
  }

  .md-ml-3 {
    padding-left: 3 * $base-font-size;
  }

  .md-mb-1 {
    margin-bottom: $base-font-size;

    &-f {
      margin-bottom: $base-font-size !important;
    }
  }

  .md-mb-2 {
    margin-bottom: 2 * $base-font-size;

    &-f {
      margin-bottom: 2 * $base-font-size !important;
    }
  }
}

@media all and (min-width: 1020px) and (max-width: 1199px) {
  .lg-mt-2 {
    margin-top: 2 * $base-font-size !important;
  }

  .lg-mb-1 {
    margin-bottom: $base-font-size;

    &-f {
      margin-bottom: $base-font-size !important;
    }
  }
}

@media all and (min-width: 1200px) {
  .xl-mt-2 {
    margin-top: 2 * $base-font-size !important;
  }
}

// Width & Height
.max-width-100 {
  max-width: 100% !important;
}

@media all and (max-width: 759px) {
  .sm-width-100 {
    width: 100%;

    &-f {
      width: 100% !important;
    }
  }
}

@media all and (min-width: 760px) {
  .md-max-width-15 {
    max-width: 15 * $base-font-size !important;
  }
}

// Numbered List
ol {
  list-style: none;
  counter-reset: item;
  // margin-bottom: 3 * $base-font-size;
  margin-top: 1.5 * $base-font-size;

  li {
    counter-increment: item;
    margin-bottom: $base-font-size;
    display: flex;
    flex-direction: row;

    & * {
      display: inline-block;
    }

    &:before {
      font-size: 1.2 * $base-font-size;
      content: counter(item);
      background: $light-blue-colour;
      border-radius: 100%;
      color: $black-colour;
      min-width: 2 * $base-font-size;
      min-height: 2 * $base-font-size;
      text-align: center;
      display: inline-block;
      align-self: start;
      line-height: 2 * $base-font-size;
    }

    & > :first-child {
      margin: 0 $base-font-size;
      font-size: 1.5 * $base-font-size;
    }
  }

  @media all and (min-width: 1020px) {
    max-width: 80%;
  }
}

// Visitbility
@media all and (max-width: 759px) {
  .sm-display-none {
    display: none !important;
  }

  .sm-display-block {
    display: block !important;
  }
}

@media all and (min-width: 760px) {
  .md-display-none {
    display: none !important;
  }

  .md-display-block {
    display: block !important;
  }
}

// Pull Quotes
blockquote {
  margin: 2 * $base-font-size auto;
  width: 70%;
  font-family: $secondary-font-family;
  font-style: italic;
}

blockquote > p {
  display: inline;
  font-size: 1.4 * $base-font-size;
}

blockquote:before,
blockquote:after {
  font-size: 2 * $base-font-size;
  color: $secondary-colour;
}

blockquote:before {
  content: "“";
}

blockquote:after {
  content: "”";
}
